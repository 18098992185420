<script setup>
const props = defineProps({
white: Boolean
})

const color = computed(() =>  props.white ? "#FFFFFF" : "#473A4E")

</script>

<template>
  <svg width="119" height="24" viewBox="0 0 119 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.97268 16.6435L15.2215 0.351562H20.1203L12.0722 23.826H8.04813L0 0.351562H5.07382L9.97268 16.6435Z" :fill="color"/>
    <path d="M29.3936 0.175781H44.615V4.20498H33.9425V9.81082H42.8654V13.84H33.9425V19.621H44.615V23.6502H29.3936V0.175781Z" :fill="color"/>
    <path d="M55.4619 0.175781H60.1858V9.81082H69.8086V0.175781H74.5325V23.6502H69.8086V13.84H60.1858V23.6502H55.4619V0.175781Z" :fill="color"/>
    <path d="M110.049 19.7956C112.323 19.7956 114.073 18.9197 114.073 17.1679C114.073 12.438 102.176 15.5912 102.176 7.18248C102.176 2.45255 105.85 0 110.399 0C113.373 0 116.347 1.05109 118.447 2.80292L116.347 5.9562C114.598 4.72992 112.323 4.0292 110.574 4.0292C108.474 4.0292 106.725 4.90511 106.725 6.83211C106.725 11.562 118.622 8.23358 118.622 16.8175C118.622 21.7226 114.773 24 110.049 24C106.55 24 103.75 22.7737 101.301 20.8467L103.4 17.5183C105.5 19.0949 107.774 19.7956 110.049 19.7956Z" :fill="color"/>
    <path d="M91.1546 0.175781H86.4307V23.6502H91.1546V0.175781Z" :fill="color"/>
  </svg>
</template>
