<script setup>
const backend = useBackend();

const props = defineProps({
  noVehisLogos: {
    type: Boolean,
    default: false
  },
})

const {data: links} = await useAsyncData(async () => {
  const {data} = await backend.getFooterLinks();
  return data;
})


</script>

<template>
  <div class="bg-black  text-xs text-white">
    <Container no-paddings>
      <div class="flex justify-between gap-6 py-6 flex-col lg:flex-row">

        <div class="md:flex md:gap-8 items-center | grid grid-cols-2 gap-10">
          <div v-if="!noVehisLogos">
            <VectorVehisOnly white />
          </div>
          <div>&copy; 2024 VEHIS sp. z o.o.</div>
        </div>

        <div class="md:flex lg:gap-8 gap-x-10 gap-y-4 | grid grid-cols-2">
          <div v-for="link in links">
            <NuxtLink :to="{name:'document', params: {slug: link.slug }}" rel="noindex">{{ link.title }}</NuxtLink>
          </div>
          <div>
            <NuxtLink :to="{name:'static-information'}">Relacje inwestorskie</NuxtLink>
          </div>
        </div>

      </div>
    </Container>
  </div>
</template>
